import HeroSection from "../Components/HeroSection"
import Aboutdesc from "../Components/Aboutdesc.js";
import AboutSection from "../Components/aboutsection.js"
function About(){
    return(
        <>
        <HeroSection/>
        <Aboutdesc/>
        <AboutSection/>
        </>
    )
}
export default About;