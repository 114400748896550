
import React from "react";
import Contact from  "../Components/Contact";
import HeroSection from "../Components/HeroSection";
import Card from "../Components/Card";
import FirstSection from "../Components/FirstSection"
function Home(){
    return(
        <>
        <FirstSection/>
        <Card/>
      
      </>
    )
}
export default Home;